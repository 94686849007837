import { SET_LOGIN_DIALOG_VISIBLE, SET_AUTH_DATA } from '../constants/actionTypes';
import initialState from './initialState';

export default function authReducer(state = initialState.auth, action) {
  switch(action.type) {
    case SET_LOGIN_DIALOG_VISIBLE: {
      return Object.assign({}, state, { loginDialogVisible: action.payload });
    }
    case SET_AUTH_DATA: {
      const isAuth = (action.payload.jwtToken !== null);
      return Object.assign({}, state, action.payload, { isAuth });
    }
    default: {
      return state;
    }
  }
}
