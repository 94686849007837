import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Panel } from 'react-bootstrap';

class PrivacyComponent extends Component {

  render() {

    return (
      <div>
        <Helmet
            title="Периоди раждане и майчинство"
          >
          <meta name="description" content="Политика за защита на личните данни" />
        </Helmet>
        <Panel>
          <h2>Политика за защита на личните данни</h2>
          Сайтът защитава личните данни на своите посетители в съответствие с българското законодателство и GDPR. Като предоставяте данните си на този сайт, вие се съгласявате всички лични данни, които ни предоставите, да бъдат обработвани за изброените по-долу цели.
          <br/><br/>
          <ul>
            <li>Статистика за посещенията в сайта чрез Google Analytics.</li>
            <li>Ако имате регистрация - вашият имейл в Facebook или Google.</li>
          </ul>
          <br/>
          <b>Вашите лични данни</b>
          <br/><br/>
          Когато създадете потребителски профил в сайта, ние съхраняваме вашият имейл адрес за идентификация на потребителя.
          <br/><br/>
          <b>Заличаване на лични данни и получаване на всички ваши данни в електронен вид</b>
          <br/><br/>
          Ако желаете да изтриете своя потребителски профил или да получите своите данни - пишете ни на <b>combyte@gmail.com</b> и ще изпълним молбата ви до 24 часа.
          <br/><br/>
          Разкриване на вашите лични данни
          <br/><br/>
          Вашите лични данни няма да бъдат разкривани на трети лица.
        </Panel>
      </div>
    );
  }
};

export default PrivacyComponent;
