import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ControlLabel, Alert, ButtonToolbar, Button, Glyphicon } from 'react-bootstrap';
import { Combobox } from 'react-widgets';
import { toastr } from 'react-redux-toastr';

import * as actions from '../actions/commonActions';
import * as plnActions from '../actions/plnActions';

import './PlnLoadSaveToolbar.css';

class PlnLoadSaveToolbar extends Component {

  constructor(...args) {
    super(...args);

    this.state = {
      selected: null,
    }
  }

  componentDidMount() {
    // зареждане на списъка с шаблоните при вход на потр. или първоначално зареждане
    this.loadShabsIfNull(this.props);
  }

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.auth.isAuth !== nextProps.auth.isAuth) {
  //     if (nextProps.auth.isAuth) {
  //       // зареждане на списъка с шаблоните при вход на потр. или първоначално зареждане
  //       this.loadShabsIfNull(nextProps);
  //     } else {
  //       // зачистване на данните за шаблоните при изход на потребителя
  //       const { plnActions, kind } = nextProps;
  //       plnActions.clearPlnFavs(kind);
  //     }
  //   }
  // }

  componentDidUpdate(prevProps) {
    if (this.props.auth.isAuth !== prevProps.auth.isAuth) {
      if (this.props.auth.isAuth) {
        // зареждане на списъка с шаблоните при вход на потр. или първоначално зареждане
        this.loadShabsIfNull(this.props);
      } else {
        // зачистване на данните за шаблоните при изход на потребителя
        const { plnActions, kind } = this.props;
        plnActions.clearPlnFavs(kind);
      }
    }
  }

  componentWillUnmount() {
    const { plnActions, kind } = this.props;
    plnActions.clearPlnFavs(kind);
  }

  loadShabsIfNull(props) {
    const { auth, plnActions, kind, pln } = props;
    const { shabs } = pln[kind];
    if (auth.isAuth && shabs === null) {
      plnActions.fetchPlnFavs(kind);
    }
  }

  getSelectedName() {
    const { selected } = this.state;
    if (typeof selected === 'string') {
      // new item
      return selected;
    } else {
      // existing item
      if (selected) {
        return selected.name;
      } else {
        return null;
      }
    }
  }

  selectPlnFav() {
    const { kind } = this.props;
    this.props.plnActions.selectPlnFav(kind, this.getSelectedName());
  }

  onSelectShab(selected) {
/*
    if ((typeof selected === "object") && (selected !== null)) {
      this.setState({ selected: selected });
    } else {
      this.setState({ selected: null });
    }
*/
    this.selectPlnFav();
  }

  onBlur() {
    this.selectPlnFav();
  }

  onChangeShab(selected) {
    /*
    let name;
    if (typeof selected === 'string') {
      // new item
      name = selected;
    } else {
      // existing item
      name = selected.name;
    }

    const { kind } = this.props;
    this.props.plnActions.selectPlnFav(kind, name);
    */
    this.setState({ selected });
  }

  visualizeSelected() {
    const { selected } = this.state;
    if (!selected) {
      return;
    }

    const { kind } = this.props;
    this.props.plnActions.visualizePlnFav(kind, selected.name);
  }

  onAddClick() {
    const { kind, plnActions } = this.props;
    plnActions.putSelectedPlnFav(kind);
  }

  onDelClick() {
    const { selected } = this.state;
    if (!selected) {
      return;
    }

    const confirmOptions = {
      onOk: () => {
        const { kind, plnActions, resetFormCallback } = this.props;
        plnActions.delSelectedPlnFav(kind, resetFormCallback);
      },
      onCancel: () => {},
      showCloseButton: false,
    };
    toastr.confirm(`Изтриване на шаблона "${selected.name}" ?`, confirmOptions);
  }

  renderNotAuthenticated() {
    return (
      <Alert bsStyle="info">
        <Button bsStyle="link" bsSize="large" onClick={() => this.props.actions.setLoginDialogVisible(true)}>
          <Glyphicon glyph="heart" />  Вход с потребител за запазване на шаблони!
        </Button>
      </Alert>
    );
  }

  render() {
    const { auth } = this.props;

    if (!auth.isAuth) {
      return this.renderNotAuthenticated();
    }

    const { pln, kind } = this.props;
    const { selected } = this.state;
    let { shabs } = pln[kind];
    if (shabs === null) {
      shabs = [];
    }

    return (
      <Alert bsStyle="info" className="pln-load-save-toolbar">
        <ControlLabel>Шаблони:</ControlLabel><br/>
        <ButtonToolbar>
          <Button onClick={this.visualizeSelected.bind(this)} bsStyle="default">
            <Glyphicon glyph="floppy-open" /> Показване
          </Button>
          <Button onClick={this.onAddClick.bind(this)} bsStyle="info">
            <Glyphicon glyph="floppy-save" /> Запис
          </Button>
          <Button onClick={this.onDelClick.bind(this)} bsStyle="danger" id="pln-load-save-del">
            <Glyphicon glyph="trash" />
          </Button>
        </ButtonToolbar>
        <Combobox
          dataKey="name"
          textField="name"
          value={selected}
          onSelect={this.onSelectShab.bind(this)}
          onBlur={this.onBlur.bind(this)}
          onChange={this.onChangeShab.bind(this)}
          data={shabs}
          filter="startsWith"
          messages={{
            open: 'Отвори списъка',
            emptyList: 'Няма записани шаблони',
            emptyFilter: 'Не са открити шаблони',
          }}
        />
      </Alert>
    );
  }
}

PlnLoadSaveToolbar.propTypes = {
  kind: PropTypes.string.isRequired,
  resetFormCallback: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    pln: state.pln,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    plnActions: bindActionCreators(plnActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlnLoadSaveToolbar);
