import React from 'react';
import Highlighter from 'react-highlight-words';
import escapeRegExp from 'lodash.escaperegexp';

let d = ({ item, searchQuery }) => {
    let { food } = item;
    let terms;
    if (searchQuery && typeof searchQuery === 'string') {
      searchQuery = escapeRegExp(searchQuery);
      terms = searchQuery.split(' ');
      if (Array.isArray(terms)) {
        terms = terms.filter(item => item.length >= 3);
      } else {
        terms = [];
      }
    } else {
      terms = [];
    }
    return (
      <span>
        <Highlighter
          searchWords={terms}
          textToHighlight={food}
          highlightTag="b"
        />
      </span>
    );
  };
  
export default d;