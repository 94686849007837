import * as consts from '../constants';
import * as types from '../constants/actionTypes';
import { ajaxError } from './commonActions';
import axios from 'axios';

const CAL_URL = consts.ROOT_URL_SSL + '/calendar/index.php';

export function fetchCalendar(year, data) {
  return {type: types.FETCH_CALENDAR, meta: year, payload: data};
}

export function fetchCalendarAsync(year) {
  return dispatch => {
    axios.get(`${CAL_URL}?year=${year}`)
      .then(function(response) {
        dispatch(fetchCalendar(year, response.data));
      })
      .catch(function (error) {
        dispatch(ajaxError(error));
      });
  };
}
