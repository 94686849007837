import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
// import GoogleLogin from 'react-google-login';

import * as actions from '../actions/commonActions';
// import * as consts from '../constants';
import history from '../history';
import { GoogleLogin } from '@react-oauth/google';

class LoginDialog extends Component {

  render() {
    // facebook callback
    // const responseFacebook = (response) => {
    //   this.props.onClose();
    //   //console.log(response);
    //   this.props.actions.loginFacebookSuccess(response);
    // }

    // google callback
    const googOk = (response) => {
      this.props.onClose();
      //console.log(response);
      this.props.actions.loginGoogleSuccess(response);
    };
    const googFail = (response) => {
      this.props.onClose();
    };

    const { show, onClose } = this.props;

    return (
      <div>
        <Modal show={show} onHide={onClose}>
          <Modal.Header closeButton>
            <Modal.Title>Вход в системата</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              Чрез цъкането на долнитя бутон, вие се съгласявате да предоставите адреса на своятя електронна поща за целите на
              идентификацията в тази сайт.
            </div>
            <hr />
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <GoogleLogin
                onSuccess={googOk}
                onError={googFail}
              />
              {/* <GoogleLogin
                clientId="526963170358-69crbpf0rn14jl3stpltjrtjep0qumcn.apps.googleusercontent.com"
                onSuccess={googOk}
                onFailure={googFail}
                scope="profile email "
                buttonText="ВХОД ЧРЕЗ GOOGLE"
              /> */}
            </div>
            <hr />
            <div>
              <a href="/privacy-policy" onClick={ e => { e.preventDefault(); history.push('/privacy-policy'); this.props.onClose(); } }>Лични данни</a>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
LoginDialog.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
}

function mapStateToProps(state) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginDialog);
