import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Jumbotron, Glyphicon, Panel, Button } from 'react-bootstrap';
import CalcContainer from '../containers/CalcContainer';

class TrudovComponent extends Component {

  constructor(props) {
    super(props);
    this.state = { moreInfo: false };
  }

  clickMoreInfo() {
    this.setState({ moreInfo: !this.state.moreInfo });
  }

  render() {
    return (
      <div>
        <Helmet
          title="Калкулатор за трудов стаж"
        >
          <meta name="description" content="Безплатен калкулатор на трудов стаж. Изчисляване по два метода - чрез изваждане и на база на календара на работните дни" />
        </Helmet>
        <Jumbotron style={{ backgroundColor: '#FFF4DB' }}>
          <h2><Glyphicon glyph='time' /> Изчисляване на трудов стаж</h2>
          <p><em>"От дата"</em> е първият работен ден за периода. <em>"До дата"</em> е първият ден, от който лицето <b>НЕ</b> е на работа.</p>
          <Button name="more" bsStyle="link" bsSize="small" className="pull-right" onClick={this.clickMoreInfo.bind(this)}>още</Button>
          {' '}
          <Panel expanded={this.state.moreInfo} className="text-info"
            style={{ border: '0', backgroundColor: '#FFF4DB' }}
            onToggle={this.clickMoreInfo}>
            <Panel.Body collapsible>
            <p>Тази справка е в каледнарни дни. За един ден стаж се счита работата
              от 4 до 8 часа на ден. При работа от 1 до 3 часа на ден се изчислява
              осигурителния стаж на база на отработените дни. Той е в работни дни
              и се превръща в календарни чрез умножаване по коефициент 30 / 21.
            </p>
            <p>Варианти:</p>
            <ol>
              <li><b>Първи вариант: </b>Месец, в който са отработени всички работни
              дни се счита за 1 месец.
              </li>
              <li><b>Втори вариант: </b>Изчислява се точният брой работни дни в
              даден месец. 1 месец = 21 отработени дни.</li>
            </ol>
            </Panel.Body>
          </Panel>
        </Jumbotron>
        <CalcContainer osigVariant={false} />
      </div>
    );
  }
}

export default TrudovComponent;
