// common
export const AJAX_ERROR = 'AJAX_ERROR';

// login & logout
export const SET_LOGIN_DIALOG_VISIBLE = 'SET_LOGIN_DIALOG_VISIBLE';
export const SET_AUTH_DATA = 'SET_AUTH_DATA';
export const LOGIN_FACEBOOK_SUCCESS = 'LOGIN_FACEBOOK_SUCCESS';

// calendar
export const FETCH_CALENDAR = 'FETCH_CALENDAR';
export const CHANGE_PERIOD = 'CHANGE_PERIOD';

// pln
export const SET_PLN_FAVS = 'SET_PLN_FAVS';
export const CLEAR_PLN_FAVS = 'CLEAR_PLN_FAVS';
export const SET_PLN_FAV_SELECTED = 'SET_PLN_FAV_SELECTED';
