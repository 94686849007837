export function removeSpaces(iban) {
  let i = 0;
  let res = '';
  for (i=0; i<iban.length; i++) {
    if (iban[i] !== ' ') {
      res += iban[i];
    }
  }
  return res;
}

function findMod(s, num) {
  let numLen = num.toString().length;
  do {
    let s2 = s.substring(0, numLen);
    if (parseInt(s2, 10) < num) {
      s2 = s.substring(0, numLen+1);
      s = s.substring(numLen+1, s.length);
    } else {
      s = s.substring(numLen, s.length);
    }
    let i2 = parseInt(s2, 10);
    let val1 = Math.floor(i2 / num);
    let val2 = i2 - val1 * num;
    if (val2 > 0) {
      s = val2.toString() + s;
    }
  } while (s.length >= 10);
  return (parseInt(s, 10) % num);
}

export function checkBIC(bic) {
  return (bic !== undefined && bic.length === 8);
}

export function checkIBAN(iban) {
  if (iban === undefined) {
    return false;
  }

  iban = removeSpaces(iban);

  if (iban.length !== 22) {
    return false;
  }

  iban = iban.toUpperCase();
  // преместване на първите 4 знака отзад
  iban = iban.substring(4, 22) + iban.substring(0, 4);
  let i = 0;
  let dig = '';
  for (i=0; i<iban.length; i++) {
    if (iban[i] >= 'A' && iban[i] <= 'Z') {
      dig += (iban[i].charCodeAt(0) - "A".charCodeAt(0) + 10).toString();
    } else {
      dig += iban[i];
    }
  }

  return (findMod(dig, 97) === 1);
}

export function checkIBAN_BIC(iban, bic) {
  let res = checkIBAN(iban) && checkBIC(bic);
  if (!res) {
    return false;
  }
  if (res) {
    return (iban.substring(4, 8) === bic.substring(0, 4));
  }
}
