import axios from 'axios';
import { initialize as initForm } from 'redux-form';
import { toastr } from 'react-redux-toastr';

import * as consts from '../constants';
import { SET_PLN_FAVS, CLEAR_PLN_FAVS, SET_PLN_FAV_SELECTED } from '../constants/actionTypes';

import { callbackAction } from './commonActions';

const PLN_FAVS_URL = consts.ROOT_URL_SSL + '/pln-favs.php';

export function setPlnFavs(kind, data) {
  return { type: SET_PLN_FAVS, payload: { kind, data } };
}

export function clearPlnFavs(kind) {
  return { type: CLEAR_PLN_FAVS, payload: kind };
}

export function setPlnFavSelected(kind, selected) {
  return { type: SET_PLN_FAV_SELECTED, payload: { kind, data: selected } };
}

export function fetchPlnFavs(kind) {
  return (dispatch, getState) => {
    // clear
    dispatch(clearPlnFavs(kind));
    let jwtToken = getState().auth.jwtToken;
    axios.get(PLN_FAVS_URL + `?kind=${kind}&method=GET`, {
      withCredentials: true,
      headers: { 'X-Authorization': jwtToken }
    })
    .then(resp => {
      if (resp.data.data) {
        // set new SHABS list
        dispatch(setPlnFavs(kind, resp.data.data));
      }
    });
  };
}

export function visualizePlnFav(kind, selectedName) {
  return (dispatch, getState) => {
    selectedName = selectedName.toLowerCase();
    const formName = plnKindToFormName(kind);
    const { shabs } = getState().pln[kind];

    // търсим дали съществува такъв шаблон
    let existing = null;
    if (shabs) {
      for (let i=0, len=shabs.length; i < len; i++) {
        if (shabs[i].name.toLowerCase() === selectedName) {
          existing = shabs[i];
          break;
        }
      }
    }

    // показване на избрания шаблон на екрана (ако съществува)
    if (existing) {
      dispatch(initForm(formName, existing.content));
    }
  }
}

export function selectPlnFav(kind, selectedName) {
  return (dispatch, getState) => {
    kind = kind.toLowerCase();

    // задаваме избраният шаблон
    dispatch(setPlnFavSelected(kind, selectedName));
  };
}

export function putSelectedPlnFav(kind) {
  return (dispatch, getState) => {
    const formName = plnKindToFormName(kind);
    const formAllData = getState().form[formName];
    const formValues = formAllData.values || {};

    const shabName = getState().pln[kind].selected;

    if (shabName === '') {
      toastr.info('Въведете име на шаблона', '');
      return;
    }

    let jwtToken = getState().auth.jwtToken;
    axios.put(PLN_FAVS_URL, null, {
      withCredentials: true,
      headers: { 'X-Authorization': jwtToken },
      params: {
        kind,
        name: shabName,
        method: 'PUT',
        content: JSON.stringify(formValues),
      }
    })
    .then(resp => {
      if (resp.data.data) {
        // set new SHABS list
        dispatch(setPlnFavs(kind, resp.data.data));
        // select the new shab
        dispatch(setPlnFavSelected(kind, resp.data.data.name));
        toastr.success('Шаблонът e записан', '');
      } else {
        const err = resp.data.error;
        toastr.error('Грешка ' + err.code, err.message);
      }
    })
    .catch(error => {
      const err = error.response.data.error;
      toastr.error('Грешка ' + err.code, err.message);
    });

  };
}

export function delSelectedPlnFav(kind, resetFormCallback) {
  return (dispatch, getState) => {
    const shabName = getState().pln[kind].selected;

    if (shabName === '') {
      toastr.info('Изберете шаблона, който искате да изтриете', '');
      return;
    }

    let jwtToken = getState().auth.jwtToken;
    axios.put(PLN_FAVS_URL, null, {
      withCredentials: true,
      headers: { 'X-Authorization': jwtToken },
      params: {
        kind,
        name: shabName,
        method: 'DELETE',
      }
    })
    .then(resp => {
      if (resp.data.data) {
        // set new SHABS list
        dispatch(setPlnFavs(kind, resp.data.data));
        // set selected shab to ''
        dispatch(setPlnFavSelected(kind, ''));
        // dispatch callback whick clears the form
        dispatch(callbackAction(resetFormCallback));
        toastr.success('Шаблонът e изтрит успешно', '');
      } else {
        const err = resp.data.error;
        toastr.error('Грешка ' + err.code, err.message);
      }
    })
    .catch(error => {
      const err = error.response.data.error;
      toastr.error('Грешка ' + err.code, err.message);
    });

  }
}

function plnKindToFormName(kind) {
  let formName;
  switch (kind) {
    case consts.PLN_KIND_STD:
      formName = 'pln';
      break;
    case consts.PLN_KIND_BUD:
      formName = 'pln-bud';
      break;
    default:
      formName = null;
  }

  return formName;
}
