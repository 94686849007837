import { SET_PLN_FAVS, CLEAR_PLN_FAVS, SET_PLN_FAV_SELECTED } from '../constants/actionTypes';
import initialState from './initialState';

export default function plnReducer(state = initialState.pln, action) {
  switch(action.type) {
    case SET_PLN_FAVS: {
      const { kind, data } = action.payload;
      const kindData = Object.assign({}, state[kind], { shabs: data });
      return Object.assign({}, state, { [kind]: kindData });
    }
    case CLEAR_PLN_FAVS: {
      const kind  = action.payload;
      return Object.assign({}, state, { [kind]: initialState.pln[kind] });
    }
    case SET_PLN_FAV_SELECTED: {
      const { kind, data } = action.payload;
      const kindData = Object.assign({}, state[kind], { selected: data });
      return Object.assign({}, state, { [kind]: kindData });
    }
    default:
      return state;
  }
}
