import React, { Component } from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux'
import * as actions from './actions/commonActions';
import Helmet from 'react-helmet';
import './App.css';
import { Navbar, Nav, NavItem, NavDropdown, MenuItem, Alert, Glyphicon, Grid, Row, Col, Button } from 'react-bootstrap';
import LoginDialog from './containers/LoginDialog';
import history from './history';

class App extends Component {

  componentDidMount() {
    this.props.actions.checkLoginStatus();  
  }

  onLoginClose() {
    this.props.actions.setLoginDialogVisible(false);
  }

  navigateMenuSelected(eventKey) {

    switch (eventKey) {
      case 1.1: {
        history.push('/calendar');
        break;
      }
      case 2.1: {
        history.push('/trudov');
        break;
      }
      case 2.2: {
        history.push('/osig');
        break;
      }
      case 2.11: {
        history.push('/mai4');
        break;
      }
      case 2.21: {
        history.push('/period');
        break;
      }
      case 3.1: {
        history.push('/pin');
        break;
      }
      case 3.2: {
        history.push('/pin-bud');
        break;
      }
      case 4.1: {
        history.push('/foods');
        break;
      }
      case 10: {
        const { auth } = this.props;
        if (!auth.isAuth) {
          this.props.actions.setLoginDialogVisible(true);
        } else {
          this.props.actions.logOut();
        }
        break;
      }
      default:
    }

    if (eventKey === 1.1) {
      history.push('/calendar');
    }
  }

  showErrors() {
    const { ajaxError } = this.props;
    if (ajaxError !== undefined && ajaxError) {
      return (
        <Alert bsStyle="danger">
          <strong>Грешка!</strong> {ajaxError}
        </Alert>
      );
    } else {
      return;
    }
  }

  navigateWelcome() {
    history.push('/');
  }

  loginButtonText() {
    const { auth } = this.props;
    if (!auth.isAuth) {
      return "Вход";
    } else {
      const renderAvatar = () => {
        if (auth.avatar) {
          return <img src={auth.avatar} alt="avatar" height="44" width="44" style={{ borderRadius: 7, opacity: 0.7, position: 'absolute', left: -6, top: -12 }} />
        } else {
          return null;
        }
      };

      return (
        <div style={{ position: 'relative' }}>
          {renderAvatar()}
          <span style={{ paddingLeft: 46 }}>{auth.userName + " (изход)"}</span>
        </div>
      )
    }
  }

  render() {
    return (
        <div className="container">
          <Helmet
            titleTemplate="%s | КомБайт Счетоводен портал"
            defaultTitle="КомБайт Счетоводен портал"
          >
            <meta charSet="utf-8" />
            <meta name="robots" content="index, follow" />
            <meta name="googlebot" content="index, follow" />
            <meta name="revisit-after" content="7 days" />
            <meta name="author" content="Combyte" />
            <meta http-equiv="Content-Language" content="bg" />

            <meta name="description" content="Онлайн помощници за счетоводители. Календар с работните дни, съставяне на (бюджетни) платежни нареждания (PIN), изчисляване на трудов и осигурителен стаж" />
          </Helmet>
          <Navbar>
              <Navbar.Header>
                  <Navbar.Brand>
                      <a href="#" onClick={this.navigateWelcome.bind()}><b style={{ color: '#757575' }}><Glyphicon glyph='paperclip' /> К</b></a>
                  </Navbar.Brand>
                  <Navbar.Toggle />
              </Navbar.Header>
              <Navbar.Collapse>
                <Nav onSelect={this.navigateMenuSelected.bind(this)}>
                    <NavDropdown eventKey={1} title="Справочник" id="basic-nav-dropdown">
                        <MenuItem eventKey={1.1}>Календар на работните дни</MenuItem>
                    </NavDropdown>
                    <NavDropdown eventKey={2} title="Калкулатор" id="basic-nav-dropdown">
                        <MenuItem eventKey={2.1}>Трудов стаж</MenuItem>
                        <MenuItem eventKey={2.2}>Осигурителен стаж</MenuItem>
                        <MenuItem divider={true}/>
                        <MenuItem eventKey={2.11}>Периоди раждане и майчинство</MenuItem>
                        <MenuItem divider={true}/>
                        <MenuItem eventKey={2.21}>Работни дни за период</MenuItem>
                    </NavDropdown>
                    <NavDropdown eventKey={3} title="Изготвяне на платежно (PIN)" id="basic-nav-dropdown">
                        <MenuItem eventKey={3.1}>за Кредитен превод</MenuItem>
                        <MenuItem eventKey={3.2}>от/към бюджета</MenuItem>
                    </NavDropdown>
                    <NavDropdown eventKey={4} title="Полезно" id="basic-nav-dropdown">
                        <MenuItem eventKey={4.1}>Калкулатор на калориите в храните</MenuItem>
                    </NavDropdown>
                </Nav>
                <Nav onSelect={this.navigateMenuSelected.bind(this)} pullRight style={{ marginRight: '10px' }}>
                  <NavItem eventKey={10} className="navbar-success">{this.loginButtonText()}</NavItem>
                </Nav>
              </Navbar.Collapse>
          </Navbar>
          <LoginDialog show={this.props.auth.loginDialogVisible} onClose={this.onLoginClose.bind(this)} />
          {this.showErrors()}
          <div>
            {this.props.children}
          </div>
          <br/>
          <br/>
          <br/>
          <div className="footer">
            <Grid>
              <div className="footer-links">
                <Row>
                  <Col md={4}><Button bsStyle="link" onClick={ () => history.push("/") }>Начало</Button></Col>
                  <Col md={4}><Button bsStyle="link" onClick={ () => history.push("/calendar") }>Календар с работните дни</Button></Col>
                  <Col md={4}><Button bsStyle="link" onClick={ () => history.push("/trudov") }>Изчисляване на трудов стаж</Button></Col>
                  <Col md={4}><Button bsStyle="link" onClick={ () => history.push("/osig") }>Изчисляване на осигурителен стаж</Button></Col>
                  <Col md={4}><Button bsStyle="link" onClick={ () => history.push("/mai4") }>Периоди раждане и майчинство</Button></Col>
                  <Col md={4}><Button bsStyle="link" onClick={ () => history.push("/period") }>Работни/календарни дни за период</Button></Col>
                  <Col md={4}><Button bsStyle="link" onClick={ () => history.push("/pin") }>Изготвяне на платежно нареждане</Button></Col>
                  <Col md={4}><Button bsStyle="link" onClick={ () => history.push("/pin-bud") }>Изготвяне на бюджетно платежно</Button></Col>
                  <Col md={4}><Button bsStyle="link" onClick={ () => history.push("/foods") }>Калкулатор на калориите в храните</Button></Col>
                  <Col md={4}><Button bsStyle="link" onClick={ () => history.push("/demo") }>Демо версии на наши програми</Button></Col>
                </Row>
              </div>
            </Grid>

            <br/>
            <br/>
            <br/>
            <div className="footer-copyright" style={{ color: '#333' }}>
              <b>Нашият софтуер</b>
              <div><Button bsStyle="link" onClick={ trackALinkClick.bind(null, 'product_prc', 'http://combyte-bg.com/%D0%BF%D1%80%D0%B5%D1%86%D0%B8%D0%B7-%D1%81%D1%85/') }>Прециз-СХ</Button></div>
              <div><Button bsStyle="link" onClick={ trackALinkClick.bind(null, 'product_skl', 'http://combyte-bg.com/%D1%81%D0%BA%D0%BB%D0%B0%D0%B4-%D1%81%D1%85/') }>Склад-СХ</Button></div>
              <div><Button bsStyle="link" onClick={ trackALinkClick.bind(null, 'product_zapi', 'http://combyte-bg.com/%d0%b7%d0%b0%d0%bf%d0%b8/') }>ЗАПИ</Button></div>
            </div>
            <br/>
            <br/>
            <br/>
            <div className="footer-copyright">
              <b style={{ color: '#757575' }}>
                <a href="http://combyte-bg.com">&copy; КомБайт - култура в счетоводството</a>
              </b>
              <br/>
              <br/>
              <b>
                <a href="/privacy-policy" onClick={ e => { e.preventDefault(); history.push('/privacy-policy'); } }>Лични данни</a>
              </b>
              <br/>
              <br/>
              <br/>
              <span style={{ color: '#757575' }}>за коментари и препоръки: office@combyte-bg.com</span>
            </div>

            <div
              className="fb-like"
              data-href="https://www.facebook.com/combyte.portal/"
              data-layout="box_count"
              data-action="like"
              data-size="small"
              data-show-faces="false"
              data-share="true">
            </div>
          </div>
        </div>
    );
  }
}

function trackALinkClick(event_cat, url) {
  window.ga('send', 'event', event_cat, 'a_click');
  window.location.href = url;
}

function mapStateToProps(state) {
  return {
    ajaxError: state.ajaxError,
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
