import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Jumbotron, Glyphicon, Form, FormGroup, ControlLabel, Button } from 'react-bootstrap';
import { DateTimePicker } from 'react-widgets';
import moment from 'moment';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as actions from '../actions/calendarActions';
import { needToFetchCalendars, calcOtrChasa } from '../stajCalc';

class DniPeriodComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      fetching: false,
      fromDate: null,
      toDate: null,
      calDays: null,
      workDays: null,
    };
  }

  changeFromDate(date) {
    this.setState({ fromDate: date });
    if (moment(date).diff(this.state.toDate, 'days') > 0) {
      this.setState({ toDate: date });
    }
  }

  changeToDate(date) {
    this.setState({ toDate: date });
    if (moment(date).diff(this.state.fromDate, 'days') < 0) {
      this.setState({ fromDate: date });
    }
  }

  resetResult() {
    this.setState({ calDays: null, workDays: null });
  }

  calcDni() {
    const { fromDate, toDate } = this.state;
    const { calendars } = this.props;

    if (!fromDate || !toDate) {
      this.resetResult();
    }
    if (!(fromDate instanceof Date)) {
      this.resetResult();
    }
    if (!(toDate instanceof Date)) {
      this.resetResult();
    }

    let mFrom = moment(fromDate);
    let mTo = moment(toDate);
    const calDays = mTo.diff(mFrom, 'days') + 1;
    if (isNaN(calDays)) {
      this.resetResult();
    } else {
      this.setState({ calDays });
    }

    // взимане на необходимите календари ако е нужно
    // find & fetch missing calendars
    let missingCals = needToFetchCalendars(fromDate, toDate, calendars);
    if (missingCals.length > 0) {
      // fetch
      this.setState({ fetching: true });
      for (let year of missingCals) {
        this.props.actions.fetchCalendarAsync(year);
      }
    } else {
      // inc 'toDate'
      const toDatePlus = new Date(toDate);
      toDatePlus.setDate(toDatePlus.getDate() + 1);
      // work hours in the interval
      let workDays = calcOtrChasa(calendars, fromDate, toDatePlus, 8, 2) / 8;
      this.setState({ workDays });

      // track event
      window.ga('send', 'event', 'period', 'calc');
    }
  }

  renderResult() {
    const { calDays, workDays } = this.state;
    if (calDays === null && workDays === null) {
      return;
    }

    let sWorkDays = '?';
    if (workDays || typeof workDays === 'number') {
      sWorkDays = workDays + '';
    }

    return (
      <div ref="resultPanel" style={{ marginTop: '20px' }} className="panel panel-primary">
        <div className="panel-body">
          <div className="text-center text-primary">
            {calDays} дни, от които {sWorkDays} работни
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.fetching) {
      // проверка дали са изтеглени всички месеци
      // и преизчисляване след като се изтеглят
      let missingCals = needToFetchCalendars(
        this.state.fromDate,
        this.state.toDate,
        this.props.calendars
      );
      if (missingCals.length === 0) {
        this.setState({ fetching: false });
        this.calcDni();
      }
    }

    return (
      <div>
        <Helmet
          title="Работни дни за период"
        >
          <meta name="description" content="Колко работни и колко календарни дни има за даден период? Изчисляване на рабодни дни межку две дати." />
        </Helmet>
        <Jumbotron style={{ backgroundColor: '#FFF4DB' }}>
          <h2><Glyphicon glyph='time' /> Работни/календарни дни за период</h2>
          <p><em>"От дата"</em> и <em>"До дата"</em> се включват в периода</p>
        </Jumbotron>
        <Form inline>
            <FormGroup controlId="fromDate"
              className='inline-date-picker'>
              <ControlLabel>От дата:</ControlLabel>
              {' '}
              <DateTimePicker value={this.state.fromDate}
                onChange={this.changeFromDate.bind(this)}
                time={false} duration={0} />
            </FormGroup>
            {' '}
            <FormGroup controlId="toDate"
              className='inline-date-picker'>
              <ControlLabel>До дата (вкл.):</ControlLabel>
              {' '}
              <DateTimePicker value={this.state.toDate}
                onChange={this.changeToDate.bind(this)}
                time={false} duration={0} />
            </FormGroup>
            {' '}
            <Button bsStyle="primary" onClick={this.calcDni.bind(this)}>
              Изчисляване
            </Button>
            {this.renderResult()}
        </Form>

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    calendars: state.calendars
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DniPeriodComponent);
