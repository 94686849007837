import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, change as fieldChange, formValueSelector, reset as resetForm } from 'redux-form';
import Helmet from 'react-helmet';
import { Form, Row, Col, Grid, Well, Button, ButtonToolbar, Glyphicon } from 'react-bootstrap';
import { sDumi } from 's-dumi';

import { renderField, renderCombobox, renderDateTimePicker } from '../components/common/Fields';
import { PLN_KIND_BUD, ROOT_URL_SSL } from '../constants';
import { checkIBAN, checkBIC, checkIBAN_BIC, removeSpaces } from '../cbValidate';
import PlnLoadSaveToolbar from './PlnLoadSaveToolbar';

const PLN_BUD_PRINT = ROOT_URL_SSL + '/pln_bud_pdf.php?data=';

const list_vidpl=[
  {id:'551111', nameS:'551111 ДОО'},
  {id:'561111', nameS:'561111 ЗОВ'},
  {id:'581111', nameS:'581111 ДЗПО'},
  {id:'110000', nameS:'110000 Данъци'}
];

const list_plsys=[
  {id:'БИСЕРА', nameS:'БИСЕРА'},
  {id:'RINGS',  nameS:'RINGS'}
];

const upper = value => value && value.toUpperCase();
const dotSep = value => value.replace(',','.');

const validate = values => {
  const errors = {};
  if (!values.narBankIme) {
    errors.narBankIme = 'Попълнете банка';
  }
  if (!values.polIme) {
    errors.polIme = 'Попълнете име';
  }
  if (!values.polIBAN) {
    errors.polIBAN = 'Попълнете IBAN';
  } else {
    if (!checkIBAN(values.polIBAN)) {
      errors.polIBAN = 'Невалиден IBAN';
    }
  }
  if (values.polBIC && values.polBIC.length > 0) {
    if (!checkBIC(values.polBIC)) {
      errors.polBIC = 'Невалиден BIC';
    } else if (!checkIBAN_BIC(values.polIBAN, values.polBIC)) {
      errors.polBIC = 'Несъвпадение между IBAN и BIC';
    }
  }
  // Ватралев: не се попълва за Търговския регистър
  //
  //if (!values.vidpl) {
  //  errors.vidpl = 'Попълнете вид плащане';
  //}
  if (!values.suma || parseFloat(values.suma > 0)) {
    errors.suma = 'Попълнете положителна сума с десетичен разделител "."';
  }
  if (!values.osnovanie) {
    errors.osnovanie = 'Попълнете основание';
  }
  if (!values.narIme) {
    errors.narIme = 'Попълнете име';
  }
  if (!values.narIBAN) {
    errors.narIBAN = 'Попълнете IBAN';
  } else {
    if (!checkIBAN(values.narIBAN)) {
      errors.narIBAN = 'Невалиден IBAN';
    }
  }
  if (!values.narBIC) {
    errors.narBIC = 'Попълнете BIC';
  } else if (!checkBIC(values.polBIC)) {
    errors.polBIC = 'Невалиден BIC';
  } else if (!checkIBAN_BIC(values.polIBAN, values.polBIC)) {
    errors.polBIC = 'Несъвпадение между IBAN и BIC';
  }
  return errors;
};

class PlnContainerBud extends Component {

  showPdf(data) {
    let url = PLN_BUD_PRINT + encodeURIComponent(JSON.stringify(data));
    window.open(
      url,
      '_blank'
    );

    // track event
    window.ga('send', 'event', 'pln-bud', 'print');
  }

  blurPolIBAN(e) {
    let value = e.target.value;
    if (value.length === 22) {
      if (!this.props.polBIC || this.props.polBIC.length === 0) {
        let sugg = value.substring(4, 8).toUpperCase() + 'BGSF';
        this.props.fieldChange('pln-bud', 'polBIC', sugg);
      }
    }
  }

  blurNarIBAN(e) {
    let value = e.target.value;
    if (value.length === 22) {
      if (!this.props.narBIC || this.props.narBIC.length === 0) {
        let sugg = value.substring(4, 8).toUpperCase() + 'BGSF';
        this.props.fieldChange('pln-bud', 'narBIC', sugg);
      }
    }
  }

  onChangeSuma(value) {
    const s = sDumi(parseFloat(value));
    this.props.fieldChange('pln-bud', 'sumsdumi', s);
  }

  clearForm() {
    this.props.resetForm('pln-bud');
  }

  componentDidMount() {
    let polIBAN = document.getElementById("polIBAN");
    polIBAN.addEventListener("blur", this.blurPolIBAN.bind(this));
    let narIBAN = document.getElementById("narIBAN");
    narIBAN.addEventListener("blur", this.blurNarIBAN.bind(this));
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Helmet
          title="(PIN) Платежно нареждане - за кредитен превод"
        >
          <meta name="description" content="Съставяне на хартиено бюджетно платежно нареждане. Онлайн софтуер по модела на PIN" />
        </Helmet>
        <Form onSubmit={handleSubmit(this.showPdf.bind(this))}>
        <Grid>
          <Row>
            <Col md={6} mdOffset={3} xs={12} className="text-center">
              <PlnLoadSaveToolbar kind={PLN_KIND_BUD} resetFormCallback={this.clearForm.bind(this)} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="visible-xs-block">
              <Well className="centered" style={{paddingTop: 0, marginTop: 10}}>
                <h3>
                Платежно нареждане/вносна бележка
                </h3>
                за плащане от/към бюджета
             </Well>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
              <Field name="narBankIme" type="text" component={renderField} label="Име на вашата банка" />
            </Col>
            <Col xs={12} md={4}>
              <Field name="narBankKlon" type="text" component={renderField} label="Клон на вашата банка" />
            </Col>
            <Col xs={12} md={4}>
              <Field name="narBankAdres" type="text" component={renderField} label="Адрес на вашата банка" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <Field name="polIme" type="text" component={renderField} label="Платете на - име на получателя" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={9}>
              <Field name="polIBAN" type="text" component={renderField} label="IBAN на получателя" normalize={compose(upper, removeSpaces)} />
            </Col>
            <Col xs={12} md={3}>
              <Field name="polBIC" type="text" component={renderField} label="BIC" normalize={compose(upper, removeSpaces)} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={10}>
              <Field name="polBankIme" type="text" component={renderField} label="При банка-име на банката на получателя" />
            </Col>
            <Col xs={12} md={2}>
              <Field name="vidpl" type="text" component={renderCombobox}
                label="Вид плащане***"
                data={list_vidpl}
                dataKey='id'
                textField='nameS'
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} xsHidden>
              <Well className="centered" style={{paddingTop: 0, marginTop: 10}}>
                <h3>
                Платежно нареждане/вносна бележка
                </h3>
                за плащане от/към бюджета
              </Well>
            </Col>
            <Col  xs={12} md={2}>
              <Field name="valuta" type="text" component={renderField} label="вид валута" placeholder="BGN"/>
            </Col>
            <Col  xs={12} md={4}>
              <Field name="suma" type="text" component={renderField} label="Сума"
                placeholder="пр. 123.45" normalize={dotSep}
                onChangeAction={this.onChangeSuma.bind(this)}
               />
            </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <Field name="sumsdumi" type="text" component={renderField} label="Сума с думи" />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <Field name="osnovanie" type="text" component={renderField} label="Основание за превод-информация за получателя" />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <Field name="osnovanie2" type="text" component={renderField} label="Още пояснения" />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={2}>
                <Field name="viddoc" type="text" component={renderField} label="Вид на документа*"/>
              </Col>
              <Col xs={12} md={7}>
                <Field name="nomerdoc" type="text" component={renderField} label="Номер на документа"/>
              </Col>
              <Col xs={12} md={3}>
                <Field name="datadoc" type="date" component={renderDateTimePicker} label="Дата на документа" />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                Период, за които се плаща
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={6}>
                <Field name="period" type="date" component={renderDateTimePicker} label="От дата" />
              </Col>
              <Col xs={6} md={6}>
                <Field name="period2" type="date" component={renderDateTimePicker} label="До дата" />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <Field name="zadulice" type="text" component={renderField} label="Задължено лице-наименование на юрид. лице или трите имена на физ.лице"/>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <Field name="bulstat" type="text" component={renderField} label="ЕИК/код по БУЛСТАТ"/>
              </Col>
              <Col xs={12} md={4}>
                <Field name="egn" type="text" component={renderField} label="ЕГН на задълж. лице"/>
              </Col>
              <Col xs={12} md={4}>
                <Field name="lnch" type="text" component={renderField} label="ЛНЧ на задълж. лице"/>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={12}>
                <Field name="narIme" type="text" component={renderField} label="Наредител-наименование на юрид. лице или трите имена на физ. лице" />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={9}>
                <Field name="narIBAN" type="text" component={renderField} label="IBAN на наредителя" normalize={compose(upper, removeSpaces)} />
              </Col>
              <Col xs={12} md={3}>
                <Field name="narBIC" type="text" component={renderField} label="BIC" normalize={compose(upper, removeSpaces)} />
              </Col>
            </Row>
            <Row>
            <Col xs={12} md={5}>
                <Field name="sistema" type="text" component={renderCombobox}
                  label="Плат.система"
                  data={list_plsys}
                  dataKey='id'
                  textField='nameS'
                />
              </Col>
              <Col xs={12} md={2}>
                <Field name="tax" type="text" component={renderField} label="Такси" />
              </Col>
              <Col xs={12} md={5}>
                <Field name="dataIzpaln" type="date" component={renderDateTimePicker} label="Дата на изпълнение" />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <ButtonToolbar>
                  <Button type="submit" bsStyle="primary" style={{ marginTop: 10 }}><Glyphicon glyph='print' /> Печат</Button>
                  <Button style={{ marginTop: 10 }} onClick={this.clearForm.bind(this)}><Glyphicon glyph='remove' /> Започване начисто</Button>
                </ButtonToolbar>
              </Col>
            </Row>
            <br/>
          </Grid>

        </Form>
      </div>
    );
  }
}

const selector = formValueSelector('pln-bud');

function mapStateToProps(state) {
  return {
    initialValues: {
      tax: '002'
    },
    suma: selector(state, 'suma'),
    polBIC: selector(state, 'polBIC'),
    narBIC: selector(state, 'narBIC')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fieldChange: bindActionCreators(fieldChange, dispatch),
    resetForm: bindActionCreators(resetForm, dispatch)
  }
}

PlnContainerBud = reduxForm({
  form: 'pln-bud',
  destroyOnUnmount: false,
  validate
})(PlnContainerBud);

PlnContainerBud = connect(mapStateToProps, mapDispatchToProps)(PlnContainerBud);

export default PlnContainerBud;
