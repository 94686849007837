import './Calendar.css';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Calendar from 'react-widgets/Calendar';
import * as actions from '../actions/calendarActions';
import moment from 'moment';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import MDSpinner from 'react-md-spinner';
import history from '../history';
import { useParams } from 'react-router-dom';

let currYear;
let currRestDays = {};

const DayComponent = (props) => {
  const { date } = props;
  const sDate = moment(date).format('YYYY-MM-DD');
  let classNameX = "center-cell";
  if (currRestDays.hasOwnProperty(`${sDate}`)) {
    classNameX += ' holiday';
  }

  return (
    <div className={classNameX}>
      {props.label}
    </div>
  );
};

class CalendarComponentInner extends Component {

  yearProp() {
    let { year } = this.props;
    if (year === undefined) {
      year = new Date().getFullYear();
    }
    return year;
  }

  restDaysProp(year) {
    let { calendars } = this.props;
    let restDays = calendars[`${year}`];
    if (restDays === undefined) {
      restDays = {};
    }
    return restDays;
  }

  componentDidMount() {
    const year = this.yearProp();
    const { calendars } = this.props;
    if (calendars === undefined || calendars[`${currYear}`] === undefined) {
      this.props.actions.fetchCalendarAsync(year);
    }
  }

  countDays(year, month) {
    let stats = { work: 0, rest: 0 };
    let day = moment([year, month, 1]);
    let sDay;
    let lastDay = day.clone().add(1, 'months');
    while (day.diff(lastDay, 'days') < 0) {

      sDay = day.format('YYYY-MM-DD');
      if (currRestDays.hasOwnProperty(`${sDay}`)) {
        stats.rest++;
      } else {
        stats.work++;
      }

      day.add(1, 'days');
    }
    return stats;
  }

  countDaysForFooter(year, month) {
    let stats = this.countDays(year, month);
    return `${stats.work} раб. / ${stats.rest} поч.`;
  }

  renderYearMenu() {
    const firstYear = 1993;
    const lastYear = new Date().getFullYear() + 1;

    let res = [];
    for (let i = lastYear; i >= firstYear; i--) {
      res.push(
        <MenuItem key={i} eventKey={i}>{i}</MenuItem>
      );
    }

    return res;
  }

  navigateYear(eventKey) {
    history.push('/calendar/' + eventKey);
  }

  render() {
    currYear = this.yearProp();
    const { calendars } = this.props;
    if (calendars === undefined || calendars[`${currYear}`] === undefined) {
      this.props.actions.fetchCalendarAsync(currYear);
      return (
        <div>
          <MDSpinner size='50' className='spinner'/>
        </div>
      );
    }
    currRestDays = this.restDaysProp(currYear);

    const title = `Календар на работните дни за ${currYear}`;

    return (
      <div>
        <Helmet
          title={title}
        >
          <meta name="description" content="Календар с работните дни по години. Официални празници, отработвания, почивни понеделници" />
        </Helmet>
        <div className="panel panel-default">
          <div className="panel-body">
            <span style={{padding: '10px'}}>Година:</span>
            <DropdownButton title={currYear} onSelect={this.navigateYear.bind()} id="chooseYear">
              {this.renderYearMenu()}
            </DropdownButton>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 rest-calendar">
            <Calendar currentDate={new Date(currYear, 0, 1)} readOnly={true}
              renderDay={DayComponent}/>
            <div className='month-summary'>
              {this.countDaysForFooter(currYear, 0)}
            </div>
          </div>
          <div className="col-md-3 rest-calendar">
            <Calendar currentDate={new Date(currYear, 1, 1)} readOnly={true}
              renderDay={DayComponent} />
            <div className='month-summary'>
              {this.countDaysForFooter(currYear, 1)}
            </div>
          </div>
          <div className="col-md-3 rest-calendar">
            <Calendar currentDate={new Date(currYear, 2, 1)} readOnly={true}
              renderDay={DayComponent} />
            <div className='month-summary'>
              {this.countDaysForFooter(currYear, 2)}
            </div>
          </div>
          <div className="col-md-3 rest-calendar">
            <Calendar currentDate={new Date(currYear, 3, 1)} readOnly={true}
              renderDay={DayComponent} />
            <div className='month-summary'>
              {this.countDaysForFooter(currYear, 3)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 rest-calendar">
            <Calendar currentDate={new Date(currYear, 4, 1)} readOnly={true}
              renderDay={DayComponent} />
            <div className='month-summary'>
              {this.countDaysForFooter(currYear, 4)}
            </div>
          </div>
          <div className="col-md-3 rest-calendar">
            <Calendar currentDate={new Date(currYear, 5, 1)} readOnly={true}
              renderDay={DayComponent} />
            <div className='month-summary'>
              {this.countDaysForFooter(currYear, 5)}
            </div>
          </div>
          <div className="col-md-3 rest-calendar">
            <Calendar currentDate={new Date(currYear, 6, 1)} readOnly={true}
              renderDay={DayComponent} />
            <div className='month-summary'>
              {this.countDaysForFooter(currYear, 6)}
            </div>
          </div>
          <div className="col-md-3 rest-calendar">
            <Calendar currentDate={new Date(currYear, 7, 1)} readOnly={true}
              renderDay={DayComponent} />
            <div className='month-summary'>
              {this.countDaysForFooter(currYear, 7)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 rest-calendar">
            <Calendar currentDate={new Date(currYear, 8, 1)} readOnly={true}
              renderDay={DayComponent} />
            <div className='month-summary'>
              {this.countDaysForFooter(currYear, 8)}
            </div>
          </div>
          <div className="col-md-3 rest-calendar">
            <Calendar currentDate={new Date(currYear, 9, 1)} readOnly={true}
              renderDay={DayComponent} />
            <div className='month-summary'>
              {this.countDaysForFooter(currYear, 9)}
            </div>
          </div>
          <div className="col-md-3 rest-calendar">
            <Calendar currentDate={new Date(currYear, 10, 1)} readOnly={true}
              renderDay={DayComponent} />
            <div className='month-summary'>
              {this.countDaysForFooter(currYear, 10)}
            </div>
          </div>
          <div className="col-md-3 rest-calendar">
            <Calendar currentDate={new Date(currYear, 11 , 1)} readOnly={true}
              renderDay={DayComponent} />
            <div className='month-summary'>
              {this.countDaysForFooter(currYear, 11)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    calendars: state.calendars
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

const CalendarComponentConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarComponentInner);

function CalendarComponent() {
  let { year } = useParams();

  return <CalendarComponentConnected year={year} />
}

export default CalendarComponent;
