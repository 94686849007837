import React from 'react';

import { Button, Glyphicon } from 'react-bootstrap';

let d = ({foods, onRemoveFood}) => {

    const renderFoods = () => {
      return foods.map((it, i) => {
        return (
          <tr key={i}>
            <td>{it.food}</td>
            <td className='text-right'>{it.cal100}</td>
            <td className='text-right'>{it.grams}</td>
            <td className='text-right'>{it.cals}</td>
            <td className='text-right'>
              <Button bsSize="xs" onClick={onRemoveFood.bind(null, i)}>
                <Glyphicon glyph='remove' />
              </Button>
            </td>
          </tr>
        );
      });
    };
  
    return (
      <table className="table table-striped table-hover">
        <tbody>
          <tr>
            <td><b>Храна или напитка</b></td>
            <td className='text-right'><b>Калории в 100гр.</b></td>
            <td className='text-right'><b>Грамаж</b></td>
            <td className='text-right'><b>Общо калории</b></td>
            <td className='text-right'></td>
          </tr>
          {renderFoods()}
        </tbody>
      </table>
    );
  }
  
export default d