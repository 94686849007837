import React, { Component } from 'react';
import { Glyphicon, Panel, Row, Col } from 'react-bootstrap';
import { Pane } from 'react-bootstrap/lib/Tab';
import history from './history';

class Welcome extends Component {

  navigateCalendar() {
    history.push('/calendar');
  }

  navigateCalcTrudov() {
    history.push('/trudov');
  }

  navigateCalcOsig() {
    history.push('/osig');
  }

  navigateCalcMai4() {
    history.push('/mai4');
  }

  navigateCalcDniPeriod() {
    history.push('/period');
  }

  navigatePln() {
    history.push('/pin');
  }

  navigatePlnBud() {
    history.push('/pln-bud');
  }

  navigateFoods() {
    history.push('/foods');
  }

  render() {
    return (
      <div>
        <Row>
          <Col md={3}>
            <Panel bsStyle="info">
              <Panel.Heading>Справочник</Panel.Heading>
              <Panel.Body>
                <div className='text-info text-center' style={{ cursor: 'pointer' }}>
                  <a onClick={this.navigateCalendar.bind()}>
                    <span style={{ fontSize: '50px' }}><Glyphicon glyph='calendar' /></span>
                    <p className="lead">
                      Календар с работните дни по години
                    </p>
                  </a>
                </div>
              </Panel.Body>
            </Panel>
          </Col>
          <Col md={3}>
            <Panel bsStyle="info">
              <Panel.Heading>Калкулатор</Panel.Heading>
              <Panel.Body>
                <div className='text-info text-center' style={{ cursor: 'pointer' }}>
                  <a onClick={this.navigateCalcTrudov.bind()}>
                    <span style={{ fontSize: '50px' }}><Glyphicon glyph='time' /></span>
                    <p className="lead">
                      Изчисляване на трудов стаж
                    </p>
                  </a>
                </div>
              </Panel.Body>
            </Panel>
          </Col>
          <Col md={3}>
            <Panel bsStyle="info" >
              <Panel.Heading>Калкулатор</Panel.Heading>
              <Panel.Body>
                <div className='text-info text-center' style={{ cursor: 'pointer' }}>
                  <a onClick={this.navigateCalcOsig.bind()}>
                    <span style={{ fontSize: '50px' }}><Glyphicon glyph='time' /></span>
                    <p className="lead">
                      Изчисляване на осигурителен стаж
                    </p>
                  </a>
                </div>
              </Panel.Body>
            </Panel>
          </Col>
          <Col md={3}>
            <Panel bsStyle="info" >
              <Panel.Heading>Калкулатор</Panel.Heading>
              <Panel.Body>
                <div className='text-info text-center' style={{ cursor: 'pointer' }}>
                  <a onClick={this.navigateCalcMai4.bind()}>
                    <span style={{ fontSize: '50px' }}><Glyphicon glyph='time' /></span>
                    <p className="lead">
                      Периоди раждане и майчинство
                    </p>
                  </a>
                </div>
              </Panel.Body>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Panel bsStyle="info">
              <Panel.Heading>Калкулатор</Panel.Heading>
              <Panel.Body>
                <div className='text-info text-center' style={{ cursor: 'pointer' }}>
                  <a onClick={this.navigateCalcDniPeriod.bind()}>
                    <span style={{ fontSize: '50px' }}><Glyphicon glyph='time' /></span>
                    <p className="lead">
                      Работни/календарни дни за период
                    </p>
                  </a>
                </div>
              </Panel.Body>
            </Panel>
          </Col>
          <Col md={3}>
            <Panel bsStyle="info">
              <Panel.Heading>Онлайн услуга</Panel.Heading>
              <Panel.Body>
                <div className='text-info text-center' style={{ cursor: 'pointer' }}>
                  <a onClick={this.navigatePln.bind()}>
                    <span style={{ fontSize: '50px' }}><Glyphicon glyph='eur' /></span>
                    <p className="lead">
                      Изготвяне на платежно нареждане
                    </p>
                  </a>
                </div>
              </Panel.Body>
            </Panel>
          </Col>
          <Col md={3}>
            <Panel bsStyle="info">
              <Panel.Heading>Онлайн услуга</Panel.Heading>
              <Panel.Body>
                <div className='text-info text-center' style={{ cursor: 'pointer' }}>
                  <a onClick={this.navigatePlnBud.bind()}>
                    <span style={{ fontSize: '50px' }}><Glyphicon glyph='eur' /></span>
                    <p className="lead">
                      Изготвяне на бюджетно платежно
                    </p>
                  </a>
                </div>
              </Panel.Body>
            </Panel>
          </Col>
          <Col md={3}>
            <Panel bsStyle="warning">
              <Panel.Heading>Полезно</Panel.Heading>
              <Panel.Body>
                <div className='text-warning text-center' style={{ cursor: 'pointer' }}>
                  <a className="text-warning" onClick={this.navigateFoods.bind()}>
                    <span style={{ fontSize: '50px' }}><Glyphicon glyph='cutlery' /></span>
                    <p className="lead">
                      Калкулатор на калориите в храните
                    </p>
                  </a>
                </div>
              </Panel.Body>
            </Panel>
          </Col>
        </Row>
      </div>
    );
  }
}

// function trackALinkClick(event_cat, url) {
//   window.ga('send', 'event', event_cat, 'a_click');
//   window.location.href = url;
// }

export default Welcome;
