const ROOT_DOMAIN = 'portal.combyte-bg.com';
const ROOT_DOMAIN_TEST_NOPROTO = 'lvh.me';
export const ROOT_DOMAIN_TEST = 'http://' + ROOT_DOMAIN_TEST_NOPROTO;
export const ROOT_URL_TEST = '/dni/api';

export let ROOT_URL;
if (process.env.NODE_ENV === "production") {
  ROOT_URL = 'https://'+ROOT_DOMAIN+'/api';
} else {
  ROOT_URL = 'http://'+ROOT_DOMAIN_TEST_NOPROTO+ROOT_URL_TEST;
}

export let ROOT_URL_SSL;
if (process.env.NODE_ENV === "production") {
  ROOT_URL_SSL = 'https://'+ROOT_DOMAIN+'/api';
} else {
  ROOT_URL_SSL = 'http://'+ROOT_DOMAIN_TEST_NOPROTO+ROOT_URL_TEST;
}

export let FB_APP_ID;
if (process.env.NODE_ENV === "production") {
  FB_APP_ID = '1268145379919334';
} else {
  FB_APP_ID = '1289825764417962';
}

export const PLN_KIND_STD = 'std';
export const PLN_KIND_BUD = 'bud';
