import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Form, FormGroup, FormControl, ControlLabel, Button } from 'react-bootstrap';
import DatePicker from 'react-widgets/DatePicker';
import MDSpinner from 'react-md-spinner';
import moment from 'moment';
import * as actions from '../actions/calendarActions';
import * as actionsCalc from '../actions/calcActions';
import { calcUP2_staj, calcUP3_staj, needToFetchCalendars } from '../stajCalc';

class CalcContainer extends Component {

  constructor(props) {
    super(props);
    let defChasa = this.props.osigVariant ? 7 : 8;
    this.mustScroll = false;
    this.state = { fromDate: null, toDate: null, chasa: defChasa, variant: 1, fetching: false, result: null };
  }

  componentDidMount() {
    this.changeFromDate = this.changeFromDate.bind(this);
    this.changeToDate = this.changeToDate.bind(this);
    this.changeChasa = this.changeChasa.bind(this);
    this.changeVariant = this.changeVariant.bind(this);
    this.calcStaj = this.calcStaj.bind(this);

    let { fromDate, toDate, chasa } = this.props.period;
    if (fromDate === undefined) {
      fromDate = null;
    }
    if (toDate === undefined) {
      toDate = null;
    }
    if (chasa === undefined) {
      chasa = 8;
    }
    this.setState({ fromDate, toDate, chasa });
  }

  componentDidUpdate() {
    if (this.mustScroll && this.refs.resultPanel) {
      if (typeof this.refs.resultPanel.scrollIntoView === 'function') {
        this.refs.resultPanel.scrollIntoView();
      }
      this.mustScroll = false;
    }
  }

  changeFromDate(date) {
    this.setState({ fromDate: date });
    if (moment(date).diff(this.state.toDate, 'days') > 0) {
      this.setState({ toDate: date });
    }
  }

  changeToDate(date) {
    this.setState({ toDate: date  });
    if (moment(date).diff(this.state.fromDate, 'days') < 0) {
      this.setState({ fromDate: date });
    }
  }

  changeChasa(e) {
    this.setState({ chasa: e.target.value });
  }

  changeVariant(e) {
    this.setState({ variant: e.target.value });
  }

  calcStaj(e) {
    if (e !== undefined) {
      e.preventDefault();
    }

    const { fromDate, toDate, chasa, variant } = this.state;
    const { calendars } = this.props;

    // store state into Redux
    this.props.actionsCalc.changePeriod(fromDate, toDate, chasa);
    // find & fetch missing calendars
    let missingCals = needToFetchCalendars(fromDate, toDate, this.props.calendars);
    if (missingCals.length > 0) {
      // fetch
      this.setState({ fetching: true });
      for (let year of missingCals) {
        this.props.actions.fetchCalendarAsync(year);
      }
    } else {
      // calc
      let result;
      if (this.props.osigVariant && chasa < 8) {
        result = calcUP3_staj(calendars, fromDate, toDate, 0, chasa, 8, false, variant);
      } else {
        result = calcUP2_staj(calendars, fromDate, toDate, 0, chasa, 8, variant);
      }
      this.setState({ result });

      // track event
      if (this.props.osigVariant) {
        window.ga('send', 'event', 'staj_osig', 'calc');
      } else {
        window.ga('send', 'event', 'staj_trudov', 'calc');
      }
    }
  }

  renderResult() {
    if (this.state.fetching) {
      return (
        <MDSpinner size='50' className='spinner' />
      );
    }

    if (!this.state.result) {
      return;
    } else {
      this.mustScroll = true;

      const { result } = this.state;
      let label = this.props.osigVariant ? 'Осигурителен стаж' : 'Трудов стаж';
      let s = `${result.y}г., ${result.m}м., ${result.d}д.`;
      if (this.props.osigVariant) {
        s += `, ${result.c}ч.`;
      }
      return (
        <div ref="resultPanel" style={{ marginTop: '20px' }} className="panel panel-primary">
          <div className="panel-body">
            <div className="text-center text-primary">
              {label}: <b>{s}</b>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    if (this.state.fetching) {
      // проверка дали са изтеглени всички месеци
      // и преизчисляване след като се изтеглят
      let missingCals = needToFetchCalendars(
        this.state.fromDate,
        this.state.toDate,
        this.props.calendars
      );
      if (missingCals.length === 0) {
        this.setState({ fetching: false });
        this.calcStaj();
      }
    }

    // without parse it commonly expects the first token to be month and fails for dates such 15.3.2024
    let formats = [
      'DD.MM.YYYY',
      'DD.MM.YY',
    ];

    return (
      <div>
        <div>
          <Form inline>
              <FormGroup controlId="fromDate"
                className='inline-date-picker'>
                <ControlLabel>От дата:</ControlLabel>
                {' '}
                <DatePicker 
                  value={this.state.fromDate}
                  onChange={this.changeFromDate}
                  duration={0}
                  parse={formats}
                  />
              </FormGroup>
              {' '}
              <FormGroup controlId="toDate"
                className='inline-date-picker'>
                <ControlLabel>До дата:</ControlLabel>
                {' '}
                <DatePicker
                  value={this.state.toDate}
                  onChange={this.changeToDate}
                  duration={0}
                  parse={formats}
                />
              </FormGroup>
              {' '}
              <FormGroup controlId="chasa">
                <ControlLabel>Раб.време:</ControlLabel>
                {' '}
                <FormControl 
                  componentClass="select" 
                  value={this.state.chasa}
                  onChange={this.changeChasa}>
                  <option value="8">8 часа</option>
                  <option value="7">7 часа</option>
                  <option value="6">6 часа</option>
                  <option value="5">5 часа</option>
                  <option value="4">4 часа</option>
                  <option value="3">3 часа</option>
                  <option value="2">2 часа</option>
                  <option value="1">1 час</option>
                </FormControl>
              </FormGroup>
              {' '}
              <FormGroup controlId="variant">
                <ControlLabel>Вариант:</ControlLabel>
                {' '}
                <select className="form-control" value={this.state.variant}
                  onChange={this.changeVariant}>
                  <option value="1">Първи</option>
                  <option value="2">Втори</option>
                </select>
              </FormGroup>
              {' '}
              <Button bsStyle="primary" type="submit" onClick={this.calcStaj}>
                Изчисляване
              </Button>
          </Form>
        </div>
        {this.renderResult()}
      </div>
    );
  }
}

CalcContainer.propTypes = {
  osigVariant: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
  return {
    period: state.period,
    calendars: state.calendars
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    actionsCalc: bindActionCreators(actionsCalc, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalcContainer);
