import { AJAX_ERROR } from '../constants/actionTypes';
import initialState from './initialState';

export default function ajaxErrorReducer(state = initialState.ajaxError, action) {
  switch(action.type) {
    case AJAX_ERROR: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
