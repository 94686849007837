import { FETCH_CALENDAR } from '../constants/actionTypes';
import initialState from './initialState';

export default function calendarReducer(state = initialState.calendars, action) {
  switch(action.type) {
    case FETCH_CALENDAR: {
      let newCal = {};
      newCal[`${action.meta}`] = action.payload;
      return Object.assign({}, state, newCal);
    }
    default:
      return state;
  }
}
