import React from 'react';
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import { Combobox, DatePicker } from 'react-widgets';
import moment from 'moment';

export const renderField = ({ name, input, label, type, placeholder, onChangeAction, meta: { touched, error } }) => {
  
  let validationState = (touched && error && 'error');
  if (!validationState) {
    validationState = null;
  }

  return (<FormGroup controlId={name} validationState={validationState}>
    <ControlLabel>{label}</ControlLabel>
    <FormControl {...input} id={input.name} type={type} placeholder={placeholder}
      onChange={(e) => {
        if (onChangeAction) {
          onChangeAction(e.target.value);
        }
        input.onChange(e);
      }}
    />
    <FormControl.Feedback />
    {touched && error && <span>{error}</span>}
  </FormGroup>);
};

function comboBoxEffectiveValue(value) {
  if (value === undefined) {
    return '';
  }

  if (value.hasOwnProperty('id')) {
    return value.id;
  } else {
    return value;
  }
}

export const renderCombobox = ({ name, input, label, type, placeholder, value, data, valueField, textField, meta: { touched, error } }) => {

  let validationState = (touched && error && 'error');
  if (!validationState) {
    validationState = null;
  }

  return (
    <FormGroup controlId={name} validationState={validationState}>
      <ControlLabel>{label}</ControlLabel>
      <Combobox {...input} type={type} placeholder={placeholder}
        data={data}
        dataKey={valueField}
        textField={textField}
        messages={{
          open: 'Показване на списък',
          emptyList: 'Списъкът е празен',
          emptyFilter: 'Не са открити такива елементи'
        }}
        onChange={(e) => {
          input.onChange(comboBoxEffectiveValue(e));
        }}
        onBlur={() => input.onBlur(value)}
      />
      <FormControl.Feedback />
      {touched && error && <span>{error}</span>}
    </FormGroup>
  );
};

export function dateToRedux(e) {
  let d = moment(e);
  if (d.isValid()) {
    return d.format("YYYY-MM-DD");
  } else {
    return null;
  }
}

export function reduxToDate(value) {
  if (value !== undefined && value !== "") {
    return moment(value).toDate();
  } else {
    return null;
  }
}

export const renderDateTimePicker = ({ name, input, label, value, data, valueField, textField, meta: { touched, error } }) => {

  let validationState = (touched && error && 'error');
  if (!validationState) {
    validationState = null;
  }

  // without parse it commonly expects the first token to be month and fails for dates such 15.3.2024
  let formats = [
    'DD.MM.YYYY',
    'DD.MM.YY',
  ];

  return (
    <FormGroup controlId={name} validationState={validationState}>
      <ControlLabel>{label}</ControlLabel>
      <DatePicker
        {...input}
        value={reduxToDate(input.value)}
        onChange={(e) => {
          input.onChange(dateToRedux(e));
        }}
        onBlur={() => input.onBlur(value)}
        rencer={formats}
      />
      {/* <Combobox {...input} type={type} placeholder={placeholder}
        data={data}
        dataKey={valueField}
        textField={textField}
        suggest={true}
        messages={{
          open: 'Показване на списък',
          emptyList: 'Списъкът е празен',
          emptyFilter: 'Не са открити такива елементи'
        }}
        onChange={(e) => {
          input.onChange(comboBoxEffectiveValue(e));
        }}
        onBlur={() => input.onBlur(value)}
      /> */}
      <FormControl.Feedback />
      {touched && error && <span>{error}</span>}
    </FormGroup>
  );
};
