let d = {
  ajaxError: '',
  auth: { isAuth: false, loginDialogVisible: false, jwtToken: null, userName: "", email: "", avatar: "" },
  pln: {
    std: { selected: '', shabs: null },
    bud: { selected: '', shabs: null },
  },
  calendars: {},
  period: { fromDate: null, toDate: null, chasa: 8 },
}

export default d
