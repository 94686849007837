import React, { Component } from 'react';

import Helmet from 'react-helmet';
import { Form, FormControl, FormGroup, ControlLabel, Row, Col, Panel, Button } from 'react-bootstrap';
import { Combobox } from 'react-widgets';
import debounce from 'lodash.debounce';
import axios from 'axios';

import { ROOT_URL } from '../constants';

import FoodsTableComponent from './FoodsTableComponent';
import FoodsComboboxItem from './FoodsComboboxItem';

class FoodsComponent extends Component {

  constructor(props) {
    super(props);

    this.foodInput = null;
    this.gramsInput = null;

    this.state = {
      searching: false,
      selectedFood: null,
      grams: "",
      foodText: "",
      foodsFound: [],
      foodsEaten: [],
      totalCals: 0,
    };
  }

  componentDidMount() {
    setTimeout(() => this.foodInput.focus(), 200);
  }

  searchFoods = debounce((queryOrObj) => {

    let query;
    if (typeof queryOrObj === 'string') {
      query = queryOrObj;
    } else {
      query = queryOrObj.food;
    }

    axios.get(ROOT_URL + '/foods/find_food.php?p='+encodeURI(query))
      .then(resp => {
        this.setState({ searching: false, foodsFound: resp.data });
      })
      .catch(err => {
        this.setState({ searching: false, foodsFound: [{id:0,food:'Грешка на сървъра'}] });
      });

  }, 300);

  onFoodChanged(selected) {
    let text;
    if (typeof selected === 'string') {
      text = selected;
    } else {
      text = selected.food;
    }

    this.setState({ foodText: text });

    this.searchFoods(text);
  }

  onFoodSelected(selected) {
    if (selected && typeof selected === 'object') {
      this.setState({ selectedFood: selected });

      setTimeout(() => this.gramsInput.focus(), 100);
    } else {
      this.setState({ selectedFood: null });
    }
  }

  onGramsKeyDown(e) {
    if (e.key === '.' || e.key === ',') {
      e.preventDefault();
    } else if (e.key === 'Enter') {
      this.addFood();
    }
  }

  onGramsChange(e) {
    this.setState({ grams: e.target.value });
  }

  calcTotalCals(foodsEaten) {
    return foodsEaten.reduce((acc, val) => acc+val.cals, 0);
  }

  addFood() {
    const { selectedFood, grams, foodsEaten } = this.state;
    if (!selectedFood) {
      return;
    }
    const iGrams = parseInt(grams, 10);
    if (iGrams <= 0) {
      return;
    }

    // calc total calories
    const newProps = {
      grams,
      cals: Math.round(grams * selectedFood.cal100 / 100)
    };
    const newFood = Object.assign({}, selectedFood, newProps);

    // add the new food
    const newFoodsEaten = [newFood, ...foodsEaten];

    // show the new food & clear UI
    this.setState({
      foodsEaten: newFoodsEaten,
      totalCals: this.calcTotalCals(newFoodsEaten),
      selectedFood: null,
      grams: "",
      foodText: "",
    });
    this.foodInput.focus();

    // track event
    window.ga('send', 'event', 'foods', 'add');
  }

  onRemoveFood(i) {
    const { foodsEaten } = this.state;
    const newFoodsEaten = [...foodsEaten.slice(0, i), ...foodsEaten.slice(i+1)];
    this.setState({
      foodsEaten: newFoodsEaten,
      totalCals: this.calcTotalCals(newFoodsEaten),
    });

    // track event
    window.ga('send', 'event', 'foods', 'remove');
  }

  render() {
    const cbMessages = {
      open: 'Отваряне на списъка',
      emptyList: 'Просто започнете да пишете името на храната или напитката!',
    };
    const { searching, foodsFound, foodsEaten, foodText, grams, totalCals } = this.state;

    return (
      <div>
        <Helmet
          title="Калкулатор на калориите в храните"
        >
          <meta name="description" content="Лесен за използване калкулатор на калории в храните. Приятелски настроен към вашия smartphone" />
        </Helmet>
        <h1 className='text-center'>Калкулатор на калориите в храните</h1>
        <h5 className='text-center'><em>Просто започнете да пишете името на храната или напитката! Използвайта Enter за избор</em></h5>
        <br/>
        <Panel bsStyle="info">
          <Panel.Body>
            <Row>
              <Form>
                <Col md={6}>
                  <FormGroup controlId="foodName">
                    <ControlLabel>Храна или напитка:</ControlLabel>
                    {' '}
                    <Combobox
                      ref={(input) => { this.foodInput = input; }}
                      value={foodText}
                      onChange={this.onFoodChanged.bind(this)}
                      onSelect={this.onFoodSelected.bind(this)}
                      busy={searching}
                      data={foodsFound}
                      dataKey="id"
                      textField="food"
                      duration={0}
                      messages={cbMessages}
                      renderListItem={({item}) =>
                        <FoodsComboboxItem item={item} searchQuery={foodText} />
                      }
                      placeholder='Пиле с ориз'
                    />
                  </FormGroup>
                </Col>
                {' '}
                <Col md={4}>
                  <FormGroup controlId="foodGrams">
                    <ControlLabel>Грамаж:</ControlLabel>
                    {' '}
                    <FormControl
                      inputRef={(input) => { this.gramsInput = input; }}
                      onKeyDown={this.onGramsKeyDown.bind(this)}
                      value={grams}
                      onChange={this.onGramsChange.bind(this)}
                      type="number"
                      min="0"
                      step="1"
                      placeholder="300"
                    />
                  </FormGroup>
                </Col>
                {' '}
                <Col md={2}>
                  <Button
                    onClick={this.addFood.bind(this)}
                    style={{marginTop: 25, width: '100%'}}
                  >
                    Прибавяне
                  </Button>
                </Col>
              </Form>
            </Row>
          </Panel.Body>
        </Panel>

        <br/>
        <h2 className="text-center"><strong>Общо калории: </strong>{totalCals}</h2>

        <FoodsTableComponent foods={foodsEaten} onRemoveFood={this.onRemoveFood.bind(this)} />

        <div style={{display: 'block', height: 200}} />
      </div>
    );
  }
}

export default FoodsComponent;
