import React, { Component } from 'react';
import Helmet from 'react-helmet';
import moment from 'moment';
import { Form, FormGroup, ControlLabel, Row, Col, Panel, Button } from 'react-bootstrap';
import { DateTimePicker } from 'react-widgets';

class Mai4Component extends Component {

  constructor(props) {
    super(props);
    this.state = { dateFirstBoln: null, birthday: null };
  }

  changeDateFirstBoln(date) {
    this.setState({ dateFirstBoln: date });
  }

  changeBirthday(date) {
    this.setState({ birthday: date });
  }

  showPeriods() {
    if (!(this.state.dateFirstBoln || this.state.birthday)) {
      return;
    }

    let res = [];
    let fmt = 'DD.MM.YYYY';
    let day410 = null;

    // дати свързани с първия болничен преди раждане
    if (this.state.dateFirstBoln) {
      let begPeriod = moment(this.state.dateFirstBoln);
      let day135 = begPeriod.clone().add(134, 'days');
      day410 = begPeriod.clone().add(409, 'days');
      res.push(<br key="1"/>);
      res.push(
        <Row key="2">
          <Col xs={12} md={6}>Болнични за раждане до 135ти ден:</Col>
          <Col xs={12} md={6}><b>{begPeriod.format(fmt)} - {day135.format(fmt)}</b></Col>
        </Row>
      );
      res.push(<br key="3"/>);
      res.push(
        <Row key="4">
          <Col xs={12} md={6}>Бременност и раждане до 410-ти ден:</Col>
          <Col xs={12} md={6}><b>{begPeriod.add(1, 'days').format(fmt)} - {day410.format(fmt)}</b></Col>
        </Row>
      );
    }

    // дати свързани с рождения ден на детето
    if (this.state.birthday) {
      let birth = moment(this.state.birthday);
      let date2y = birth.clone().add(2, 'years');
      let sBeg = 'до ';
      if (day410) {
        sBeg = day410.clone().add(1, 'days').format(fmt)+' - ';
      }
      res.push(<br key="5"/>);
      res.push(
        <Row key="6">
          <Col xs={12} md={6}>Отглеждане на малко дете до 2г.:</Col>
          <Col xs={12} md={6}><b>{sBeg}{date2y.format(fmt)}</b></Col>
        </Row>
      );
    }
    res.push(<br key="7"/>);

    // track event
    window.ga('send', 'event', 'mai4', 'calc');

    return (
      <Panel bsStyle="success">
        <Panel.Heading>Периоди</Panel.Heading>
        <Panel.Body>
          {res}
        </Panel.Body>
      </Panel>
    )
  }

  render() {
    return (
      <div>
        <Helmet
          title="Периоди раждане и майчинство"
        >
          <meta name="description" content="Изчислете кога трябва да подаване Удостоверение 10 до 410-ти ден и за отглеждане на малко дете" />
        </Helmet>
        <Form>
          <FormGroup controlId="dateFirstBoln">
            <ControlLabel>Начална дата на първия болничен (45 дни преди раждането):</ControlLabel>
            <DateTimePicker id="dateFirstBoln" value={this.state.dateFirstBoln}
              onChange={this.changeDateFirstBoln.bind(this)}
              time={0} duration={0} />
          </FormGroup>
          <FormGroup controlId="birthday">
            <ControlLabel>Рожден ден на детето:</ControlLabel>
            <DateTimePicker id="birthday" value={this.state.birthday}
              onChange={this.changeBirthday.bind(this)}
              time={0} duration={0} />
          </FormGroup>
          <Row>
            <div className="col-md-12 text-center">
              <Button bsStyle="success">Изчисляване</Button>
            </div>
          </Row>
          <br/>
        </Form>
        {this.showPeriods()}
      </div>
    );
  }
}

export default Mai4Component;
