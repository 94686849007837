// react-widgets
import Moment from 'moment';
import 'moment/locale/bg';
import MomentLocalizer from 'react-widgets-moment';
import 'react-widgets/styles.css';

// react
import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';

// redux
import reducers from './reducers';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import initialState from './reducers/initialState';
import reduxThunk from 'redux-thunk';
import * as storage from 'redux-storage';
import createEngine from 'redux-storage-engine-localstorage';
import storageFilter from 'redux-storage-decorator-filter';
import storageDebounce from 'redux-storage-decorator-debounce';
import ReduxToastr, {reducer as toastrReducer} from 'react-redux-toastr';
import { reducer as formReducer } from 'redux-form';

import axios from 'axios';

import { checkLoginStatus } from './actions/commonActions';
import App from './App';
import Welcome from './Welcome';
import CalendarComponent from './containers/Calendar';
import TrudovComponent from './components/TrudovComponent';
import OsigComponent from './components/OsigComponent';
import PlnContainer from './containers/PlnContainer';
import PlnContainerBud from './containers/PlnContainerBud';
import Mai4Component from './components/Mai4Component';
import DemoContainer from './containers/DemoContainer';
import FoodsComponent from './components/FoodsComponent';
import DniPeriodComponent from './components/DniPeriodComponent';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import PrivacyComponent from './components/PrivacyComponent';
import { Localization } from 'react-widgets';
import history from './history';
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router';
import ErrorBoundary from './ErrorBoundary'
import { GoogleOAuthProvider } from '@react-oauth/google';

// set default localizer for 'react-widgets'
Moment.locale('bg');
const localizer = new MomentLocalizer(Moment);

// fix annoying bug
axios.defaults.withCredentials = true;

// hook the base reducer with redux-storage
const rootReducer = storage.reducer(combineReducers({
  ...reducers,
  router: connectRouter(history),
  form: formReducer,
  toastr: toastrReducer,
}));

// create the storage engine
let engine = createEngine('cbPortal');
// add filter decorator
engine = storageFilter(engine, [
  // whitelist
  'form'
], [
  // blacklist
  ['form', 'pln', 'initial'],
  ['form', 'pln-bud', 'initial']
])
// add debounce decorator
engine = storageDebounce(engine, 1500);
// create middleware
const storageMiddleware = storage.createMiddleware(engine);

// Add the reducer to your store on the `routing` key
const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(
      reduxThunk, 
      storageMiddleware,
      routerMiddleware(history),
    ),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )
);

// load the state from the storage
const loadStorage = storage.createLoader(engine);
loadStorage(store);

// enable Google Analytics on navigation
history.listen(location => {
  if (window.ga) {
    window.ga('send', 'pageview', location.pathname);
  }
});

// при първоначално пускане се изпълнява checkLoginStatus - проверява дали
// потребителя е вътре в системата и ако е показва неговото име и аватар
const onAppInit = (dispatch) => {
  return (nextState, replace, callback) => {
    dispatch(checkLoginStatus());
    callback();
  };
}

// текстове на бутоните на react-redux-toastr
const toastrTextOptions = {
  okText: 'Добре',
  cancelText: 'Отказ'
};

ReactDOM.render((
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <GoogleOAuthProvider clientId="526963170358-69crbpf0rn14jl3stpltjrtjep0qumcn.apps.googleusercontent.com">
          <Localization date={localizer}>
            <div>
              <App>
                <ErrorBoundary>
                  <Switch>
                    <Route path="/calendar/:year"><CalendarComponent /></Route>
                    <Route path="/calendar"><CalendarComponent/></Route>
                    <Route path="/trudov"><TrudovComponent/></Route>
                    <Route path="/osig"><OsigComponent/></Route>
                    <Route path="/pln"><PlnContainer/></Route>
                    <Route path="/pin"><PlnContainer/></Route>
                    <Route path="/mai4"><Mai4Component/></Route>
                    <Route path="/period"><DniPeriodComponent/></Route> 
                    <Route path="/pln-bud"><PlnContainerBud/></Route>
                    <Route path="/pin-bud"><PlnContainerBud/></Route>
                    <Route path="/demo"><DemoContainer/></Route>
                    <Route path="/foods"><FoodsComponent/></Route>
                    <Route path="/privacy-policy"><PrivacyComponent/></Route>
                    <Route index path="/"><Welcome/></Route>
                  </Switch>
                </ErrorBoundary>
              </App>
              <ReduxToastr
                    timeOut={4000}
                    newestOnTop={false}
                    preventDuplicates={true}
                    position="bottom-right"
                    transitionIn="fadeIn"
                    transitionOut="fadeOut"
                    progressBar
                    confirmOptions={toastrTextOptions}
              />
            </div>
          </Localization>
        </GoogleOAuthProvider>
      </ConnectedRouter>
    </Provider>
  ),document.getElementById('root')
);

