import ajaxErrorReducer from './ajaxErrorReducer';
import calendarReducer from './calendarReducer';
import periodReducer from './periodReducer';
import authReducer from './authReducer';
import plnReducer from './plnReducer';

const rootReducer = {
  ajaxError: ajaxErrorReducer,
  auth: authReducer,
  pln: plnReducer,
  calendars: calendarReducer,
  period: periodReducer,
};

export default rootReducer;
