import moment from 'moment';

export function calcTrudovStajPreizch(otrChasa, zakonChasa) {

  if (zakonChasa === 0) {
    zakonChasa = 8;
  }

  otrChasa = Math.round(otrChasa * 30 / 21);
  let c = otrChasa % zakonChasa;
  let d = Math.floor(otrChasa / zakonChasa);
  let m = Math.floor(d / 30);
  d %= 30;
  let y = Math.floor(m / 12);
  m %= 12;

  return { y, m, d, c };
}

export function calcOtrChasa(calendars, begDate, endDate, naChasa, variant) {
  if (!(begDate instanceof Date) || !(endDate instanceof Date)) {
    return;
  }

  // clone endDate and add -1 days
  endDate = new Date(endDate.getTime());
  endDate.setDate(endDate.getDate()-1);

  let res = 0;

  let y1 = begDate.getFullYear();
  let m1 = begDate.getMonth() + 1;
  let d1 = begDate.getDate();
  let y2 = endDate.getFullYear();
  let m2 = endDate.getMonth() + 1;
  let d2 = endDate.getDate();
  if (y1 === y2 && m1 === m2) {
    // началото и края са в един месец
    res = countMonthOtrDni(calendars, y1, m1, d1, d2);
  } else {
    let day = moment([y1, m1-1, d1]);
    let lastDay = moment([y2, m2-1, d2]);
    let begRest = countMonthOtrDni(calendars, y1, m1, 1, d1-1);
    let endRest = countMonthOtrDni(calendars, y2, m2, d2+1, 31);
    if (begRest > 0) {
      // започва с непълен месец
      res += countMonthOtrDni(calendars, y1, m1, d1, 31);
      day.add(1, 'months').date(1);
    }
    if (endRest > 0) {
      // завършва на непълен месец
      res += countMonthOtrDni(calendars, y2, m2, 1, d2);
      day.date(1);
    }
    while (day.diff(lastDay, 'days') < 0) {
      if ((+variant) === 2) {
        // вариант 2 - реален брой отработени дни
        res += countMonthOtrDni(calendars, day.year(), day.month()+1, 1, 31);
      } else {
        // вариант 1 - пълния месец винаги е 21 дни
        res += 21;
      }
      day.add(1, 'months');
    }
  }
  return (res * naChasa);
}

export function countMonthOtrDni(calendars, year, month, fromDay, toDay) {

  let thisYearCalendar = calendars[`${year}`];
  if (thisYearCalendar === undefined) {
    thisYearCalendar = {};
  }

  let day = moment([year, month-1, 1]);
  let lastDay = day.clone().add(1, 'month');
  let res = 0;
  while (day.diff(lastDay, 'days') < 0) {
    let sDay = day.format('YYYY-MM-DD');
    let dayOfMonth = day.date();
    if (dayOfMonth >= fromDay
      && dayOfMonth <= toDay
      && !thisYearCalendar.hasOwnProperty(sDay)) {
      res++;
    }

    day = day.add(1, 'days');
  }
  return res;
}

export function calcTrudovStaj(begDate, endDate) {
  if (!(begDate instanceof Date) || !(endDate instanceof Date)) {
    return;
  }

  let y1 = begDate.getFullYear();
  let m1 = begDate.getMonth() + 1;
  let d1 = begDate.getDate();

  let y2 = endDate.getFullYear();
  let m2 = endDate.getMonth() + 1;
  let d2 = endDate.getDate();

  if (d2 < d1) {
    d2 += 30;
    --m2;
  }
  if (m2 < m1) {
    m2 += 12;
    --y2;
  }

  let y = y2-y1, m = m2-m1, d = d2-d1;

  return { y, m, d, c: 0 };
}

export function calcOsigStaj(otrChasa,zakonChasa) {
  if (zakonChasa === 0) {
    zakonChasa = 8;
  }

  let c = otrChasa % zakonChasa;
  let d = Math.floor(otrChasa / zakonChasa);
  let m = Math.floor(d / 21);
  d %= 21;
  let y = Math.floor(m / 12);
  m %= 12;
  return { y, m, d, c };
}

export function needToFetchCalendars(begDate, endDate, calendars) {
  if (!(begDate instanceof Date) || !(endDate instanceof Date)) {
    return [];
  }

  let begYear = begDate.getFullYear();
  let endYear = endDate.getFullYear();
  let res = [];
  for (let i=begYear; i<=endYear; i++) {
    if (calendars === undefined || calendars[`${i}`] === undefined) {
      res.push(i);
    }
  }
  return res;
}

export function calcUP2_staj(calendars, begDate, endDate, otrChasa, naChasa, zakonChasa, variant) {
  if (naChasa >= 4 && naChasa <= 8) {
    return calcTrudovStaj(begDate, endDate);
  } else {
    if (otrChasa === 0) {
      otrChasa = calcOtrChasa(calendars, begDate, endDate, naChasa, variant);
    }
    return calcTrudovStajPreizch(otrChasa, zakonChasa);
  }
}

export function calcUP3_staj(calendars, begDate, endDate, otrChasa, naChasa, zakonChasa, calcInCalendarDays, variant) {
  if (naChasa === zakonChasa) {
    return calcTrudovStaj(begDate, endDate);
  } else {
    if (otrChasa === 0) {
      otrChasa = calcOtrChasa(calendars, begDate, endDate, naChasa, variant);
    }
    if (calcInCalendarDays) {
      return calcTrudovStajPreizch(otrChasa, zakonChasa);
    } else {
      return calcOsigStaj(otrChasa, zakonChasa);
    }
  }
}
