import { CHANGE_PERIOD } from '../constants/actionTypes';
import initialState from './initialState';

export default function periodReducer(state = initialState.period, action) {
  switch(action.type) {
    case CHANGE_PERIOD: {
      return {
        fromDate: action.payload.fromDate,
        toDate: action.payload.toDate,
        chasa: action.payload.chasa 
      };
    }
    default:
      return state;
  }
}
