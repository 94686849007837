import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Helmet from 'react-helmet';
import { Alert, Button, Glyphicon, Panel } from 'react-bootstrap';

import { ROOT_URL_SSL } from '../constants';
import * as actions from '../actions/commonActions';

const DOWN_DEMO_URL = ROOT_URL_SSL + '/download-demo.php?file=';

class DemoContainer extends Component {

  openDownloadWindow(fileName) {
    const { jwtToken } = this.props.auth;

    let url = DOWN_DEMO_URL
      + encodeURIComponent(fileName)
      + "&token=" + encodeURIComponent(jwtToken);
    window.open(
      url,
      '_blank'
    );
  }

  renderHead() {
    return (
      <h2><center>Изтегляне на демо версии</center></h2>
    );
  }

  renderMustLogin() {
    return (
      <Alert bsStyle="info">
        <Button bsStyle="link" bsSize="large" onClick={() => this.props.actions.setLoginDialogVisible(true)}>
          <Glyphicon glyph="download" />  Вход с потребител за изтегляне на демо версия!
        </Button>
      </Alert>
    );
  }

  renderDownloadTable() {
    return (
      <Panel>
        <table className="table table-responsive table-striped">
          <thead>
            <tr>
              <th>Продукт</th>
              <th>Описание</th>
              <th>Версия към дата</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>КомБайт ТРЗ ЗАПИ</td>
              <td><em>ТРЗ и ЛС</em></td>
              <td>27.01.2022</td>
              <td>
                <Button className="pull-right" onClick={this.openDownloadWindow.bind(this,"install_zapi.zip")}>
                  <Glyphicon glyph="download" />Изтегляне
                </Button>
              </td>
            </tr>
            <tr>
              <td>КомБайт Прециз-СХ</td>
              <td><em>Счетоводен софтуер</em></td>
              <td>27.01.2022</td>
              <td>
                <Button className="pull-right" onClick={this.openDownloadWindow.bind(this,"install_prcwin.zip")}>
                  <Glyphicon glyph="download" />Изтегляне
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
        <Alert bsStyle="warning">
          <Glyphicon glyph="info-sign" />
          &nbsp;<b>Важно!</b>&nbsp;В демо версията на продукта Прециз-СХ се влиза с потребителско име: 123 и парола 456
        </Alert>
      </Panel>
    );
  }

  renderBody() {
    const { isAuth } = this.props.auth;
    const doRender = (isAuth) => {
      if (isAuth) {
        return this.renderDownloadTable();
      } else {
        return this.renderMustLogin();
      }
    };

    return (
      <div>
        <Helmet
          title="Изтегляне на демо версии"
        >
          <meta name="description" content="КомБайт Софтуер - изтегляне на демо версии - Прециз-СХ, Склад-СХ, ЗАПИ" />
        </Helmet>
        {doRender(isAuth)}
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderHead()}
        {this.renderBody()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  }
}

DemoContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DemoContainer);

export default DemoContainer;
