import jwtDecode from 'jwt-decode';
import axios from 'axios';

import * as consts from '../constants';
import * as types from '../constants/actionTypes';
import initialState from '../reducers/initialState';

const LOGIN_STATUS_URL = consts.ROOT_URL_SSL + '/login-status.php';
const LOGOUT_URL = consts.ROOT_URL_SSL + '/logout.php';
const FB_LOGIN_URL = consts.ROOT_URL_SSL + '/fb-login.php';
const GOOG_LOGIN_URL = consts.ROOT_URL_SSL + '/goog-login.php';

export function callbackAction(callback) {
  return (dispatch) => {
    callback();
  };
}

export function ajaxError(error) {
  return {type: types.AJAX_ERROR, payload: error};
}

export function setLoginDialogVisible(visible) {
  return { type: types.SET_LOGIN_DIALOG_VISIBLE, payload: visible };
}

export function loginFacebookSuccess(resp) {
  return (dispatch) => {
    axios.get(FB_LOGIN_URL + "?token=" + resp.accessToken, {
      withCredentials: true,
    })
      .then(resp => {
        // save the JWT token to local storage
        localStorage.setItem('token', resp.data.token);
        dispatch(setJwtToken(resp.data.token));
      })
      .catch(error => {
        // remove then JWT token from local storage
        localStorage.removeItem('token');
        dispatch(setJwtToken(null));
      });
  };
}

export function loginGoogleSuccess(resp) {
  return (dispatch) => {
    axios.get(GOOG_LOGIN_URL + "?id_token=" + resp.credential, {
      withCredentials: true,
    })
      .then(resp => {
        // save the JWT token to local storage
        localStorage.setItem('token', resp.data.token);
        dispatch(setJwtToken(resp.data.token));
      })
      .catch(error => {
        // remove then JWT token from local storage
        localStorage.removeItem('token');
        dispatch(setJwtToken(null));
      });
  };
}

export function setJwtToken(token) {
  return (dispatch) => {

    if (token === null || token === undefined) {
      dispatch(setAuth(initialState.auth));
      return;
    }

    try {
      let data = jwtDecode(token);
      data = { jwtToken: token, userName: data.userName, email: data.email, avatar: data.avatar };
      dispatch(setAuth(data));
    } catch (e) {
      dispatch(setAuth(initialState.auth));
    }
  };
}

export function checkLoginStatus() {
  return (dispatch) => {
    const jwtToken = localStorage.getItem('token');
    if (jwtToken === null || jwtToken === undefined) {
      // no token in local storage
      dispatch(setJwtToken(null));
      return;
    }

    axios.get(LOGIN_STATUS_URL + "?token=" + jwtToken)
      .then(resp => {
          if (resp.data === true) {
            let data = jwtDecode(jwtToken);
            data = { jwtToken: jwtToken, userName: data.userName, email: data.email, avatar: data.avatar };
            dispatch(setAuth(data));
          } else {
            dispatch(setJwtToken(null));
          }
      })
      .catch(error => {
        dispatch(setJwtToken(null));
      });
  };
}

export function logOut() {
  return (dispatch) => {
    localStorage.removeItem('token');

    axios.get(LOGOUT_URL)
      .then(resp => {
        dispatch(setJwtToken(null));
      })
      .catch(error => {
        dispatch(setJwtToken(null));
      });
  };
}

export function setAuth(data) {
  return {type: types.SET_AUTH_DATA, payload: data};
}
